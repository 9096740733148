import React, { useRef, useEffect } from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-intl";
import clsx from "clsx";
import { useSpring, animated } from "react-spring";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { MainJumbotron } from "../components/jumbotron";
import { SlidingTypography, SlidingDiv } from "../components/sliding";
import ContainerBox from "../components/containerBox";
import { Arrow } from "../components/icons";

const useStyles = makeStyles((theme: Theme) => createStyles({
  contents: {
    padding: "160px 0",
  },
  section: {
    paddingTop: "160px",
    paddingBottom: "80px",

    "&:first-child": {
      paddingTop: 0,
      paddingBottom: "80px",
    },
    "&:last-child": {
      paddingTop: "160px",
      paddingBottom: 0,
    },
  },
  sectionRightEnd: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  mainImage: {
    width: "100%",
    height: "360px",
    objectFit: "cover",
  },
  countUpDiv: {
    minWidth: "187px",

    [theme.breakpoints.up("xs")]: {
      marginBottom: "40px",
      "&:last-child": {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "40px",
      marginBottom: 0,
    },
  },

  // typography
  heading2: {
    fontWeight: 700,
    lineHeight: "1.2",
    marginBottom: "16px",
    maxWidth: "700px",

    [theme.breakpoints.up("xs")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "46px",
    },
  },
  heading3: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#b4b4b4",
    marginBottom: "18px",
  },
  paragraph: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#858585",
    maxWidth: "630px",
    marginBottom: "8px",
  },
  dataParagraph: {
    fontSize: "14px",
    color: "#b4b4b4",
    marginBottom: "30px",
  },
  numbers: {
    marginTop: "-20px",
    fontSize: "44px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
  },
  featuredText: {
    color: "#00dd99",
    fontSize: "16px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    marginLeft: "9px",
    "& img": {
      marginRight: "5px",
    },
  },
  learnMore: {
    color: "#00dd99",
    fontSize: "14px",
    textDecoration: "none",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",

    "& > svg": {
      fontSize: "14px",
      marginLeft: "4px",
    },

    "&:hover": {
      color: "#b4b4b4",
    },
  },
  counterWrapper: {
    display: "flex",

    marginTop: "80px",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
}));

interface Props {
  pageContext: {
    language: string;
  };
} 

function IndexPage({ pageContext }: Props) {
  const { language } = pageContext;
  const intl = useIntl();
  const classes = useStyles();
  const numberSectionRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(graphql`
    query {
      placeholderImage1: file(
        relativePath: { eq: "company/img-main-01@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage2: file(
        relativePath: { eq: "company/img-main-02@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage3: file(
        relativePath: { eq: "company/img-main-03@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [spring, set] = useSpring<{
    languages: number;
    offices: number;
    nationalities: number;
  }>(() => ({
    languages: 0,
    offices: 0,
    nationalities: 0,
  }));

  const handleInitSpring = () => {
    if (
      numberSectionRef.current &&
      numberSectionRef.current.offsetTop > window.pageYOffset
    ) {
      set({
        languages: 18,
        offices: 4,
        nationalities: 20,
        delay: 200,
        config: {
          duration: 500,
        },
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInitSpring);
    return () => {
      window.removeEventListener("scroll", handleInitSpring);
    };
  });

  return (
    <Layout language={language}>
      <SEO
        title="Home"
        description={intl.formatMessage({ id: "about_lead_1" })}
      />
      <MainJumbotron />
      <main className={classes.contents}>
        <ContainerBox className={classes.section}>
          <SlidingTypography
            className={classes.heading2}
            variant="h2"
            gutterBottom
          >
            Grow Rapidly {"&"} <br />
            Expand Globally
          </SlidingTypography>
          <SlidingTypography
            component="p"
            className={classes.paragraph}
            gutterBottom
          >
            {intl.formatMessage({ id: "about_lead_1" })}
          </SlidingTypography>
          <div className={classes.counterWrapper} ref={numberSectionRef}>
            <div className={classes.countUpDiv}>
              <SlidingTypography
                variant="h3"
                className={classes.heading3}
                gutterBottom
              >
                {intl.formatMessage({ id: "count_language_title" })}
              </SlidingTypography>
              <SlidingTypography
                component="p"
                className={classes.numbers}
                gutterBottom
              >
                <animated.span>
                  {spring.languages.interpolate(Math.floor)}
                </animated.span>
                <span className={classes.featuredText}>
                  {intl.formatMessage({ id: "count_languag_unit" })}
                </span>
              </SlidingTypography>
            </div>
            <div className={classes.countUpDiv}>
            </div>
            <div className={classes.countUpDiv}>
              <SlidingTypography
                variant="h3"
                className={classes.heading3}
                gutterBottom
              >
                {intl.formatMessage({ id: "count_colleague_title" })}
              </SlidingTypography>
              <SlidingTypography
                component="p"
                className={classes.numbers}
                gutterBottom
              >
                <animated.span>
                  {spring.nationalities.interpolate(Math.floor)}
                </animated.span>
                <span className={classes.featuredText}>
                  <img src="/main/ic-number-add.svg" alt="countries" />
                  {intl.formatMessage({ id: "count_colleague_unit" })}
                </span>
              </SlidingTypography>
            </div>
          </div>
        </ContainerBox>

        <ContainerBox leftOnly>
          <Img
            className={classes.mainImage}
            fluid={data.placeholderImage1.childImageSharp.fluid}
            alt="Grow Rapidly, Expand Globally"
          />
        </ContainerBox>

        <ContainerBox
          className={clsx(classes.section, classes.sectionRightEnd)}
        >
          <div>
            <SlidingTypography
              className={classes.heading2}
              variant="h2"
              gutterBottom
            >
              Technology Driven
            </SlidingTypography>
            <SlidingTypography
              component="p"
              className={classes.paragraph}
              gutterBottom
            >
              {intl.formatMessage({ id: "about_lead_2" })}
            </SlidingTypography>
            <SlidingTypography
              component="p"
              className={classes.dataParagraph}
              gutterBottom
            >
              {intl.formatMessage({ id: "about_lead_2_1" })}
            </SlidingTypography>
            <SlidingDiv>
              <Link to="/tech" className={classes.learnMore}>
                <span>{intl.formatMessage({ id: "learn_more" })}</span>
                <Arrow />
              </Link>
            </SlidingDiv>
          </div>
        </ContainerBox>

        <ContainerBox rightOnly>
          <Img
            className={classes.mainImage}
            fluid={data.placeholderImage2.childImageSharp.fluid}
            alt="Technology Driven Company"
          />
        </ContainerBox>

        <ContainerBox className={classes.section}>
          <div>
            <SlidingTypography
              variant="h2"
              className={classes.heading2}
              gutterBottom
            >
              Creating Enjoyment
            </SlidingTypography>
            <SlidingTypography
              component="p"
              className={classes.paragraph}
              gutterBottom
            >
              {intl.formatMessage({ id: "about_lead_3" })}
            </SlidingTypography>
          </div>
        </ContainerBox>

        <ContainerBox leftOnly>
          <Img
            className={classes.mainImage}
            fluid={data.placeholderImage3.childImageSharp.fluid}
            alt="Creating Enjoyment"
          />
        </ContainerBox>
      </main>
    </Layout>
  );
};

export default IndexPage;
